document.addEventListener('DOMContentLoaded', () => {
  const iconWrappers = document.querySelectorAll('.Question__section__content__icon-wrapper')

  iconWrappers.forEach(wrapper => {
    wrapper.addEventListener('click', () => {
      const icon = wrapper.querySelector('.Question__section__content__icon')
      const innerContent = wrapper.closest('.Question__section__content__wrapper')?.querySelector('.Question__section__content__inner')

      icon?.classList.toggle('active')
      innerContent?.classList.toggle('active')
    })
  })
})

// scrollIconAreaをタッチするとscrollIconAreaをdisplay: none;にする
document.addEventListener('DOMContentLoaded', () => {
  const scrollIconArea = document.getElementById('scrollIconArea') as HTMLElement
  const scrollIcon = document.querySelector('.Comparison__section__table__wrapper__mask__scroll-icon') as HTMLElement

  // タッチ開始時に実行
  scrollIconArea?.addEventListener('touchstart', () => {
    scrollIcon.style.display = 'none';
  })

  // スクロール開始時に実行
  scrollIconArea?.addEventListener('touchmove', () => {
    scrollIcon.style.display = 'none';
  })
})