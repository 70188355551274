document.addEventListener('DOMContentLoaded', () => {
  const addClass = '-active'
  const humburger = document.getElementById('jsHumBurger')
  const hbLines = document.querySelectorAll('.Header-sp__hb-line')
  const spNavWrapper = document.querySelector('.sp-nav__wrapper')
  const HeaderSp = document.querySelector('.Header-sp')
  const body = document.body
  const fixedBtn = document.querySelector('#fixedBtn')

  humburger?.addEventListener('click', () => {
    // Header-sp__hb-lineクラスを持つ全ての要素に-activeクラスをトグルする
    hbLines.forEach(line => {
      line.classList.toggle(addClass)
    })

    // sp-nav__wrapperクラスを持つ要素に-activeクラスをトグルする
    spNavWrapper?.classList.toggle(addClass)

    // Header-spクラスを持つ要素に-activeクラスをトグルする
    HeaderSp?.classList.toggle(addClass)

    fixedBtn?.classList.toggle(addClass)

    // bodyタグに-activeクラスをトグルする
    body.classList.toggle(addClass)
  })


  
  const pageLinks = document.querySelectorAll("a.Header__left-nav__menu-link")
  pageLinks.forEach(function(link) {
    link.addEventListener("click", function() {
      humburger?.classList.remove(addClass) // ハンバーガーボタンからクラスを削除
      hbLines.forEach(line => {
        line.classList.remove(addClass)
      })

      HeaderSp?.classList.remove(addClass)

      spNavWrapper?.classList.remove(addClass) // メニューからクラスを削除
      fixedBtn?.classList.remove(addClass)

      body.classList.remove(addClass) // ボディからクラスを削除
    })
  })
})

